@media screen and (max-width: 1023px) {
	body { font-size: 14px; }
}
@media screen and (max-width: 767px) {
	body { font-size: 13px; }
}

.parallax-banner-1 {
	background: url(../images/bg-head.jpg);
}
.parallax-banner-2 {
	background: url(../images/bg-philosophie.jpg);
}
.parallax-banner-3 {
	background: url(../images/bg-service.jpg);
}
.parallax-banner-5 {
	background: url(../images/bg-kontakt.jpg);
}
.parallax-banner-6 {
	background: url(../images/bg-kompetenzen.jpg);
}
.referenzen-line {
	text-align: center;
}
.referenzen-line .section-title {
	opacity: 1 !important;
	font-size: 20px;
	margin-top: 40px;
	margin-bottom: 40px;
}
.text-center {
	text-align: center;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
	.tablet-margin-left-16 {
		margin-left: 16.66667%;
	}

}
@media screen and (max-width: 767px) {
	.social-icon {
		margin-bottom: 10px;
	}
}

.logo {
	opacity: 0.75;
}

.hero .hero-holder {
	width: 960px;
}

.hero .hero-logo {
	margin-bottom: 20px;
	opacity: 0.8;
}