/*------------------------------------------------------------

	#Reset & Basics (Inspired by E. Meyers)

--------------------------------------------------------------*/

body { line-height: 1; -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: 100%; }

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, caption, canvas, center, cite, code,
dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, form, footer, header, hgroup, h1, h2, h3, h4, h5, h6, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav,object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, tt, table, tbody, textarea, tfoot, thead, time, tr, th, td, u, ul, var, video  { 
	font-family: inherit; 
	font-size: 100%; 
	font-weight: inherit; 
	font-style: inherit; 
	vertical-align: baseline; 
	white-space: normal; 
	margin: 0; 
	padding: 0; 
	border: 0; 
	outline: 0;
	background: transparent; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { 
	display: block; }
								  
ol, ul { list-style: none; }

blockquote, q { quotes: none; }

:focus { outline: 0; }

table { 
	border-collapse: collapse; 
	border-spacing: 0; }

img { border: 0; -ms-interpolation-mode: bicubic; vertical-align: middle; }